import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

export const useDeployments = (pagination) => {
  return useQuery({
    queryKey: ["deployments", pagination],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const response = await fetch(
        `/deployments.json?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`,
      );
      if (!response.ok) {
        throw new Error("failed to fetch data");
      }

      const data = await response.json();

      return {
        rows: data.deployments,
        rowCount: data.total,
        pageCount: Math.ceil(data.total / pagination.pageSize),
      };
    },
  });
};

export const useAddDeployment = (pagination) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await fetch("/deployments.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token":
            document
              .querySelector('[name="csrf-token"]')
              ?.getAttribute("content") || "",
        },
        body: JSON.stringify({
          deployment: {
            deployment_status: null,
            app_in_date: null,
            e_sign_date: null,
            application_status: null,
            approved_date: null,
            deployment_date: null,
            merchant_id: null,
            mid_type: null,
            sales_channel_id: null,
            processor_id: null,
            platform_id: null,
            projected_volume: 0,
            bp_price: 0,
            per_transaction: 0,
            cc_ach_form: null,
            hardware_payment_term: null,
            deployment_type: null,
            pci_required: false,
            fixed_rate: false,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create deployment");
      }

      return response.json();
    },
    onSuccess: (newDeployment) => {
      // Update the cache with the new deployment
      queryClient.setQueryData(["deployments", pagination], (oldData) => {
        if (!oldData)
          return { rows: [newDeployment], rowCount: 1, pageCount: 1 };

        return {
          rows: [...oldData.rows, transformedDeployment],
          rowCount: oldData.rowCount + 1,
          pageCount: Math.ceil((oldData.rowCount + 1) / pagination.pageSize),
        };
      });

      // Scroll to the new row
      setTimeout(() => {
        const newRow = document.getElementById(`row-${newDeployment.id}`);
        if (newRow) {
          newRow.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
  });
};

export const useUpdateDeployment = (pagination) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ deploymentId, columnId, newValue }) => {
      const response = await fetch(`/deployments/${deploymentId}.json`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token":
            document
              .querySelector('[name="csrf-token"]')
              ?.getAttribute("content") || "",
        },
        body: JSON.stringify({
          deployment: {
            [columnId]: newValue,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update deployment");
      }

      return response.json();
    },
    onSuccess: (updatedDeployment) => {
      queryClient.setQueryData(["deployments", pagination], (oldData) => {
        if (!oldData) return;

        const updatedRows = oldData.rows.map((d) =>
          d.id === updatedDeployment.id ? { ...d, ...updatedDeployment } : d,
        );

        return {
          ...oldData,
          rows: updatedRows,
        };
      });
    },
  });
};

export const useDeleteDeployment = (pagination) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (deploymentId) => {
      const response = await fetch(`/deployments/${deploymentId}.json`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token":
            document
              .querySelector('[name="csrf-token"]')
              ?.getAttribute("content") || "",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete deployment");
      }
    },
    onSuccess: (_, deploymentId) => {
      queryClient.setQueryData(["deployments", pagination], (oldData) => {
        if (!oldData) return;

        const updatedRows = oldData.rows.filter((d) => d.id !== deploymentId);
        return {
          ...oldData,
          rows: updatedRows,
          rowCount: oldData.rowCount - 1,
          pageCount: Math.ceil((oldData.rowCount - 1) / oldData.rows.length),
        };
      });
    },
  });
};
