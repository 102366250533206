export const getUserTableState = async () => {
  try {
    const response = await fetch("/users/deployments_table_state", {
      method: "GET",
    });

    return response.json();
  } catch (error) {
    console.error("Error loading preferences:", error);
  }
};

export const updateUserTableState = async (
  columnOrder,
  columnVisibility,
  columnSizes,
) => {
  try {
    const response = await fetch("/users/save_deployments_table_state", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token":
          document
            .querySelector('[name="csrf-token"]')
            ?.getAttribute("content") || "",
      },
      body: JSON.stringify({
        column_order: columnOrder,
        column_visibility: columnVisibility,
        column_sizes: columnSizes,
      }),
    });
  } catch (error) {
    console.error("Error saving preferences:", error);
  }
};
